import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ComicsPage from '../components/ComicsPage';

const Root = () => (
  <div>
    <Switch>
      <Redirect path="/" exact to={'/nyx_no_lantern/english'} />
      <Route path="/:name?/:language?" component={ComicsPage} />
    </Switch>
  </div>
);

export default Root;
