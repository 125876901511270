import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useParams, useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Comics from '../resources/comics.json';
import Translations from '../resources/translations.json';

const PAGE_WIDTH = 764;

export default function ComicsPage() {
  const { name, language } = useParams();
  const comic = Comics[name];
  const image = require(`../resources/${name}_${language}.jpg`);
  const history = useHistory();

  function handleSelectName(event) {
    const selectedName = {
      hell_dogs: 'nyx_no_lantern',
      nyx_no_lantern: 'sennen_kitsune',
      sennen_kitsune: 'hell_dogs',
    }[name];
    history.push(`/${selectedName}/${language}`);
  }

  function handleSelectLanguage(event) {
    const selectedLanguage = event.target.value;
    history.push(selectedLanguage);
  }

  const [open, setOpen] = React.useState(false);

  const [addition, setAddition] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoodClose = () => {
    setAddition(1);
    setOpen(false);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Box maxWidth={PAGE_WIDTH} justifyContent="center" py={1} px={1}>
        <Grid container spacing={3}>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item>
              <FormControl style={{ minWidth: 120 }}>
                <InputLabel>
                  {Translations['SELECT_LANGUAGE'][language]}
                </InputLabel>
                <Select
                  labelId="SELECT_LANGUAGE"
                  onChange={handleSelectLanguage}
                  defaultValue={language}
                >
                  <MenuItem value={'english'}>English</MenuItem>
                  <MenuItem value={'italian'}>Italiana</MenuItem>
                  <MenuItem value={'spanish'}>Español</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                endIcon={<NavigateNextIcon />}
                onClick={handleSelectName}
                Send
              >
                {Translations['TURN_TO_NEXT_PAGE'][language]}
              </Button>
            </Grid>
          </Grid>

          <Grid item>
            <Card style={{ maxWidth: PAGE_WIDTH }}>
              <CardMedia component="img" image={image} />
            </Card>
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Badge
                badgeContent={comic.subscribers + addition}
                color="secondary"
              >
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<EmailIcon />}
                  onClick={handleClickOpen}
                  Send
                >
                  {Translations['OPEN_DIALOGUE_TO_JOIN_EMAIL_LIST'][language]}
                </Button>
              </Badge>
            </Grid>

            <Grid item>
              <a rel="noopener noreferrer" href={comic.creator}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<EmojiPeopleIcon />}
                  Send
                >
                  {Translations['OPEN_SOCIAL_MEDIA_PAGE_OF_CREATOR'][language]}
                </Button>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {Translations['OPEN_DIALOGUE_TO_JOIN_EMAIL_LIST'][language]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {Translations['EMAIL_LIST_EXPLANATION'][language]}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={Translations['EMAIL_ADDRESS'][language]}
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {Translations['CANCEL_JOIN_EMAIL_LIST'][language]}
            </Button>
            <Button onClick={handleGoodClose} color="primary">
              {Translations['COMMIT_JOIN_EMAIL_LIST'][language]}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Grid>
  );
}
